import React, { useRef, useEffect } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../../store";
import Graph from "../../../shared/graph";

import s from "./social-facts-graph.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const delay = 400; //graph delay

const SocialFactsGraph = ({
  visuals: { bgImage, bgColor, items },
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const bgRef = useRef();
  const innerRef = useRef();
  const graphWrapperRef = useRef();
  const itemsRef = useRef([]);
  const timeline = useRef(null);

  //mock domain for social chart, slideshow speed
  const data = useStore((s) => s.socialChart);
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  //used for domain bounds
  const maxX = data.length;
  const maxY = Math.max(...data.map((i) => i.y));

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true, delay: delay / 1000 });
    timeline.current
      .fromTo(
        innerRef.current,
        {
          autoAlpha: 1,
          y: "70%",
          transformOrigin: "0 0%",
          rotate: 90,
        },
        {
          y: "0%",
          rotate: 0,
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        itemsRef.current,
        {
          autoAlpha: 0,
          y: "10%",
          scale: 1.2,
        },
        {
          autoAlpha: 1,
          scale: 1,
          y: "0%",
          stagger: 0.2,
          duration: 0.3,
          ease: "power4.out",
          onComplete: () => {
            onSlideChange &&
              setTimeout(() => {
                onSlideChange();
              }, slideShowSpeed);
          },
        }
      )
      .fromTo(
        bgRef.current,
        {
          scale: 1,
        },
        {
          scale: 1.2,
          duration: 20,
        }
      );
  }, [itemsRef, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  const Item = ({ label, value, hasEmphasis, index }) => {
    return (
      <div className={cx(s.item)} ref={(e) => (itemsRef.current[index] = e)}>
        <span className={cx(s.label)}>{label}</span>
        <span className={cx(s.value, { hasEmphasis })}>{value}</span>
      </div>
    );
  };

  return (
    <div className={cx(s.wrapper, {})} style={{ backgroundColor: bgColor }}>
      <div className={s.visuals}>
        <div className={s.inner} ref={innerRef}>
          <div className={s.contentWrapper}>
            <div className={s.graphWrapper} ref={graphWrapperRef}>
              <Graph
                plotData={data}
                xDomain={[1, maxX]}
                yDomain={[0, maxY]}
                color="#fff"
              />
            </div>
            <div className={s.items}>
              {items.map((item, index) => (
                <Item
                  key={index}
                  hasEmphasis={index < 2}
                  label={item.label}
                  value={item.value}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* if has background image */}
      {bgImage && (
        <div ref={bgRef} className={s.bgWrapper}>
          <div
            className={cx(s.bgImage)}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      )}
    </div>
  );
};

SocialFactsGraph.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    items: PropTypes.array,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
};

export default SocialFactsGraph;
