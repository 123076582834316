import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import useInterval from "@use-it/interval";

import useStore from "../../../../store";
import Graph from "../../../shared/graph";
import ItemValue from "../../../shared/item-value";
import Tag from "../../../shared/tag";
import BackgroundForeground from "../../../shared/background-foreground";

import s from "./top-single.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const speed = 30; //stop motion speed
const delay = 800; //graph delay

const TopSingle = ({
  visuals: { items, bgColor },
  data,
  isActive,
  isPrev,
  tag = "",
  onSlideChange,
}) => {
  const [interval, setLoopInterval] = useState(speed);
  const i = useRef(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //mock domain for first, second and third places
  const chartData = useStore((s) => s.podiumChart);

  //used for domain bounds
  const maxX = chartData.length;
  const maxY = Math.max(...chartData.map((i) => i.y));

  //current data - used for stop motion animation
  const [currentData, updateData] = useState(
    chartData.map((i) => ({ x: i.x, y: 0 }))
  );

  //initial delay
  setTimeout(() => isActive && !shouldAnimate && setShouldAnimate(true), delay);

  //reset step counter and current data
  const resetPlot = useCallback(() => {
    i.current = 0;
    setShouldAnimate(false);
    updateData(chartData.map((i) => ({ x: i.x, y: 0 })));
  }, [chartData]);

  //reset plot if slide is no longer in view
  useEffect(() => {
    isPrev && resetPlot();
  }, [isPrev, resetPlot]);

  //loop (stop-motion)
  useInterval(() => {
    if (i.current >= chartData.length || !isActive || !shouldAnimate) return;
    const tempData = [...currentData];
    tempData[i.current] = chartData[i.current];
    updateData(tempData);
    i.current++;
  }, interval);

  //cancel or retrigger loop
  useEffect(() => {
    (i.current >= chartData.length || !isActive) && setLoopInterval(null);
    i.current < chartData.length && isActive && setLoopInterval(speed);
  }, [currentData, chartData.length, isActive]);

  return (
    <div className={cx(s.wrapper)} style={{ backgroundColor: bgColor }}>
      <div className={s.visuals}>
        <div className={s.visual}>
          <BackgroundForeground
            data={items[0]}
            isActive={isActive}
            delay={delay / 1000 + 0.1}
            index={0}
            onSlideChange={onSlideChange}
          />
        </div>
      </div>
      <Graph
        xDomain={[1, maxX]}
        yDomain={[0, maxY]}
        color="#fff"
        plotData={currentData}
        isActive={isActive}
        isPrev={isPrev}
        delay={0.7 + delay / 1000}
      />
      <div className={s.data}>
        <div className={s.item}>
          <ItemValue
            data={data}
            isActive={isActive}
            isPrev={isPrev}
            delay={delay / 1000 + 0.1}
          />
        </div>
      </div>
      {tag && <Tag tag={tag} isActive={isActive} isPrev={isPrev} delay={0.5} />}
    </div>
  );
};

TopSingle.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    items: function (props, propName) {
      if (props.items.length !== 1) {
        return new Error(`${propName} needs to be an array of a single object`);
      }

      return null;
    },
  }),
  data: PropTypes.object,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  tag: PropTypes.string,
};

export default TopSingle;
