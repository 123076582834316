import startCase from "lodash.startcase";

const prismicFetcher = (...args) =>
  fetch(...args)
    .then((res) => res.json())
    .then(({ results }) => {
      return results.map(({ data, type }) => {
        const category = data?.category?.[0]?.text;
        const title = data?.title?.[0]?.text;
        const subtitle = data?.subtitle?.[0]?.text;
        const description = data?.description?.[0]?.text;

        return {
          type: startCase(type).replace(" ", ""),
          visuals: {
            bgMedia:
              type === "message"
                ? data.media.url
                : "http://victorinox-tvscreen-app.elespacio.net/temp_assets/images/polygon-social.png",
          },
          info: {
            content: {
              title: title,
              subtitle: subtitle,
              category: category,
              description: type === "message" ? description : null,
            },
            bg: {
              type: "solid",
              value: "#fff",
            },
          },
          data,
        };
      });
    });

export default prismicFetcher;
