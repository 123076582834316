import React from "react";
import PropTypes from "prop-types";

import ProgressPlot from "./types/progress-plot";
import Evolution from "./types/evolution";
import TopThree from "./types/top-three";
import TopSingle from "./types/top-single";
import Message from "./types/message";
import ProductLaunch from "./types/product-launch";
import SocialGraph from "./types/social-graph";
import SocialTopPosts from "./types/social-top-posts";
import SocialFacts from "./types/social-facts";
import SocialFactsGraph from "./types/social-facts-graph";
import InfoAndVisuals from "./info-and-visuals";

import s from "./slide.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const components = {
  ProgressPlot,
  TopThree,
  TopSingle,
  Evolution,
  Message,
  ProductLaunch,
  SocialGraph,
  SocialFacts,
  SocialFactsGraph,
  SocialTopPosts,
};

const Slide = ({ type, isActive, onSlideChange, ...props }) => {
  const Component = components[type];

  return (
    <div className={cx(s.slide, { isActive })}>
      <InfoAndVisuals
        type={type}
        isActive={isActive}
        Component={Component}
        onSlideChange={onSlideChange}
        {...props}
      />
    </div>
  );
};

Slide.propTypes = {
  type: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  onSlideChange: PropTypes.func,
};

export default Slide;
