import React from "react";
import PropTypes from "prop-types";
import Info from "../info";

import s from "./infoAndVisuals.module.scss";

const InfoAndVisuals = ({
  type,
  info,
  isActive,
  data,
  visuals,
  isPrev,
  tag,
  Component,
  onSlideChange,
  showInfoAndVisuals,
  ...props
}) => {
  const tagProp = tag ? { tag } : {};

  const renderSlide = () => (
    <Component
      data={data}
      visuals={visuals}
      isActive={isActive}
      isPrev={isPrev}
      onSlideChange={onSlideChange}
      {...tagProp}
      {...props}
    />
  );

  if (!showInfoAndVisuals) {
    return renderSlide();
  }

  return (
    <>
      <div className={s.info}>
        {/* left panel: text info */}
        <Info content={info.content} bg={info.bg} isActive={isActive} />
      </div>
      <div className={s.visuals}>
        {/* right panel: visuals - images, graphs, colors, text components, other info */}
        {renderSlide()}
      </div>
    </>
  );
};

InfoAndVisuals.propTypes = {
  type: PropTypes.string.isRequired,
  visuals: PropTypes.object.isRequired,
  data: PropTypes.any,
  info: PropTypes.object.isRequired,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  tag: PropTypes.string,
  Component: PropTypes.func,
  onSlideChange: PropTypes.func,
};

export default InfoAndVisuals;
