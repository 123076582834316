import React, { useState, useEffect } from "react";

import useSWR from "swr";
import useStore from "./store";

import fetcher from "./utils/fetcher.js";
import prismicFetcher from "./utils/prismicFetcher.js";

import "./styles/app.scss";

import Slide from "./components/slide";

// eslint-disable-next-line no-unused-vars
const TEST_API = "https://run.mocky.io/v3/0cff1af1-6ecb-4536-9d48-eb22f777fc80";
const PROD_API =
  "https://victorinox-api.elespacio.net/api/v1?token=isudf786dfiuhsdf87sdf875sdf897";

const API_URL = PROD_API;

const PRELOADABLE_KEYS = ["bgImage", "bg", "image"];
// theme related images
const ADDITIONAL_SOURCES_TO_PRELOAD = [
  "https://victorinox-tvscreen-app.elespacio.net/temp_assets/images/social-bg4.jpg",
  "https://victorinox-tvscreen-app.elespacio.net/temp_assets/images/Rank1.png",
];

const range = (n) => [...Array(n).keys()];

const App = ({ prismicRef }) => {
  const onFetchSuccess = () => {
    setNumberOfFetchedRequests((x) => x + 1);
  };
  const { data: apiData, error } = useSWR(API_URL, fetcher, {
    onSuccess: onFetchSuccess,
    revalidateOnFocus: false,
  });
  const { data: prismicData, error: prismicError } = useSWR(
    prismicRef
      ? encodeURI(
          `${process.env.REACT_APP_PRISMIC_API_URL}/documents/search?ref=${prismicRef}&format=json&access_token=${process.env.REACT_APP_PRISMIC_API_ACCESS_TOKEN}&pageSize=100`
        )
      : null,
    prismicFetcher,
    {
      onSuccess: onFetchSuccess,
      revalidateOnFocus: false,
    }
  );
  const [numberOfFetchedRequests, setNumberOfFetchedRequests] = useState(0);
  const [data, setData] = useState(false);
  const [isPreloaded, setPreloaded] = useState(false);
  const [loadingPercentage, setLoadingPercentage] = useState(0);

  //pull from store
  const setIndent = useStore((s) => s.setIndent);
  const total = useStore((s) => s.total);
  const setTotal = useStore((s) => s.setTotal);
  const activeIndex = useStore((s) => s.activeIndex);
  const setActiveIndex = useStore((s) => s.setActiveIndex);
  const prevIndex = useStore((s) => s.prevIndex);
  const setPrevIndex = useStore((s) => s.setPrevIndex);

  //set global indent from _variables.scss to position graphs/plots
  const indent = getComputedStyle(document.documentElement).getPropertyValue(
    "--indent"
  );

  const preloadableSources = [];

  useEffect(() => {
    const _apiData = apiData?.length ? apiData : [];
    const _prismicData = prismicData?.length ? prismicData : [];
    if (
      !data &&
      numberOfFetchedRequests === 2 &&
      _prismicData.length > 0 &&
      _apiData.length > 0
    ) {
      setData([..._apiData, ..._prismicData]);
    }
  }, [numberOfFetchedRequests, apiData, prismicData, data]);

  // Some data validations before we kick off
  useEffect(() => {
    // it must be an array
    if (data && !Array.isArray(data)) {
      console.error("Error: Data is not an array.");
      return;
    }
    if (data && Array.isArray(data) && data.length === 0) {
      console.error("Error: Data cannot be empty.");
      return;
    }
    data && setPreloadableSources();
    preloadableSources.length &&
      preloadAllData().then(() => {
        // start something cool?
        setIndent(indent);
        setTotal(data?.length);
        setTimeout(() => {
          setPreloaded(true);
        }, 500);
      });
    // eslint-disable-next-line
  }, [data]);

  const setPreloadableSources = () => {
    data.forEach((obj, i) => {
      if (obj?.info?.bg?.type === "image") {
        preloadableSources.push(obj.info.bg.value);
      }
      for (let j = 0; j < PRELOADABLE_KEYS.length; j++) {
        const key = PRELOADABLE_KEYS[j];
        if (obj?.media?.kind === key) {
          preloadableSources.push(obj?.media?.url);
        }
        if (obj?.visuals?.[key]) {
          preloadableSources.push(obj.visuals[key]);
        }
        if (obj?.visuals?.items) {
          for (let k = 0; k < obj.visuals.items.length; k++) {
            const item = obj.visuals.items[k];
            if (item[key]) {
              preloadableSources.push(item[key]);
            }
          }
        }
      }
    });
    for (let k = 0; k < ADDITIONAL_SOURCES_TO_PRELOAD.length; k++) {
      preloadableSources.push(ADDITIONAL_SOURCES_TO_PRELOAD[k]);
    }
  };

  const preloadAllData = () => {
    const allPromises = [];
    let totalLoaded = 0;
    if (!preloadableSources) return;
    for (let i = 0; i < preloadableSources.length; i++) {
      allPromises.push(
        // eslint-disable-next-line no-loop-func
        new Promise((resolve, reject) => {
          const img = new Image();
          img.onload = () => {
            totalLoaded++;
            setLoadingPercentage(
              Math.round((totalLoaded / preloadableSources.length) * 100)
            );
            resolve();
          };
          img.onerror = () => {
            reject();
          };
          img.src = preloadableSources[i];
        })
      );
    }
    return Promise.all(allPromises).then(
      () => {},
      (error) => {
        console.error("Could not load all data: ", error);
      }
    );
  };

  const getIndexes = (dir = 1) => {
    const index = activeIndex === null ? 0 : (activeIndex + dir) % total;
    return {
      index: index,
      prevIndex:
        activeIndex == null ? null : index === 0 ? total - 1 : index - dir,
    };
  };

  const handleSlideChange = () => {
    const indexes = getIndexes();
    setActiveIndex(indexes.index);
    setPrevIndex(indexes.prevIndex);
  };

  const shouldShowInfoAndVisuals = (slide) => {
    if (slide.type === "Message") {
      const isImage = slide?.data?.media?.kind === "image";
      if (!isImage) return false;
    }

    return true;
  };

  return (
    <>
      {(error || prismicError) && (
        <div className="preloader">Please refresh and try again.</div>
      )}
      {!error && !prismicError && !isPreloaded && (
        <div className="preloader">{loadingPercentage}%</div>
      )}
      {data?.length && isPreloaded && (
        <div className="App">
          {/* Poor mans debugging below: */}
          {/* <Slide
            key={1}
            isPrev={false}
            isActive={true}
            type={data[1]?.type}
            visuals={data[1]?.visuals}
            data={data[1]?.data || data[1]}
            info={data[1]?.info}
            tag={data[1]?.tag || ""}
            // onSlideChange={handleSlideChange}
          /> */}
          {/* Slideshow goes here  - Array of slide components */}
          {range(total).map((i) => (
            <Slide
              key={i}
              isPrev={i === prevIndex && i !== activeIndex}
              isActive={i === activeIndex}
              type={data[i]?.type}
              visuals={data[i]?.visuals}
              data={data[i]?.data || data[i]}
              info={data[i]?.info}
              tag={data[i]?.tag || ""}
              onSlideChange={handleSlideChange}
              showInfoAndVisuals={shouldShowInfoAndVisuals(data[i])}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default App;
