import React, { useRef, useEffect } from "react";
import gsap, { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../../store";

import s from "./social-facts.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const delay = 400; //graph delay

const SocialFacts = ({
  visuals: { bgImage, bgColor, items, caption },
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const bgRef = useRef();
  const innerRef = useRef();
  const rankShape = useRef();
  const itemsRef = useRef([]);
  const timeline = useRef(null);
  const bgTween = useRef(null);

  //mock domain for social chart, slideshow speed
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  useEffect(() => {
    gsap.set(innerRef.current, {
      autoAlpha: 1,
      y: "70%",
      transformOrigin: "0 0%",
      rotate: 90,
    });
    rankShape.current &&
      gsap.set(rankShape.current, {
        autoAlpha: 0,
        y: "70%",
        transformOrigin: "0 0%",
      });

    timeline.current = new TimelineMax({
      paused: true,
      delay: delay / 1000,
      onComplete: () => {
        bgTween.current = gsap.fromTo(
          bgRef.current,
          {
            scale: 1,
          },
          {
            scale: 1.2,
            duration: 20,
          }
        );
        onSlideChange &&
          setTimeout(() => {
            onSlideChange();
            bgTween.current.kill();
          }, slideShowSpeed);
      },
    });
    timeline.current
      .fromTo(
        innerRef.current,
        {
          autoAlpha: 1,
          y: "70%",
          transformOrigin: "0 0%",
          rotate: 90,
        },
        {
          y: "0%",
          rotate: 0,
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        itemsRef.current,
        {
          autoAlpha: 0,
          y: "10%",
          scale: 1.2,
        },
        {
          autoAlpha: 1,
          scale: 1,
          y: "0%",
          stagger: 0.2,
          duration: 0.3,
          ease: "power4.out",
        }
      );
    rankShape.current &&
      timeline.current.fromTo(
        rankShape.current,
        {
          autoAlpha: 0,
          y: "70%",
          transformOrigin: "0 0%",
        },
        {
          autoAlpha: 1,
          y: "0%",
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      );
  }, [itemsRef, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  const Item = ({ label, value, hasEmphasis, index }) => {
    return (
      <div className={cx(s.item)} ref={(e) => (itemsRef.current[index] = e)}>
        <span className={cx(s.label)}>{label}</span>
        <span className={cx(s.value, { hasEmphasis })}>{value}</span>
      </div>
    );
  };

  return (
    <div className={cx(s.wrapper, {})} style={{ backgroundColor: bgColor }}>
      {/* if has background image */}
      {bgImage && (
        <div ref={bgRef} className={s.bgWrapper}>
          <div
            className={cx(s.bgImage)}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      )}
      <div className={s.visuals}>
        <div className={s.inner} ref={innerRef}>
          <div className={s.items}>
            {items.map((item, index) => (
              <Item
                key={index}
                hasEmphasis={index < 2}
                label={item.label}
                value={item.value}
                index={index}
              />
            ))}
          </div>
        </div>
      </div>
      {caption && (
        <div className={cx(s.rankShape, `rank${caption.rank}`)} ref={rankShape}>
          <div className={s.rankCaption}>
            <div className={s.rankTitle}>{caption.title}</div>
            {caption.subtitle && (
              <div className={s.rankSubtitle}>{caption.subtitle}</div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

SocialFacts.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    items: PropTypes.array,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
};

export default SocialFacts;
