const fetcher = (...args) => {
  const [url, ...rest] = args;

  return fetch(
    `${url}${
      url.includes("prismic")
        ? ""
        : url.includes("?")
        ? window.location.search?.replaceAll("?", "&")
        : window.location.search
    }`,
    ...rest
  ).then((res) => res.json());
};

export default fetcher;
