import React from "react";
import ReactDOM from "react-dom";
import useSWR from "swr";
import App from "./App";
import fetcher from "./utils/fetcher.js";
import * as serviceWorker from "./serviceWorker";

const PRISMIC_API_URL = encodeURI(
  `${process.env.REACT_APP_PRISMIC_API_URL}?access_token=${process.env.REACT_APP_PRISMIC_API_ACCESS_TOKEN}`
);

const AppWrapper = () => {
  const { data } = useSWR(PRISMIC_API_URL, fetcher);

  return (
    <React.StrictMode>
      <App prismicRef={data?.refs[0]?.ref} />
    </React.StrictMode>
  );
};

ReactDOM.render(<AppWrapper />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
