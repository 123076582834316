import React, { useRef, useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import gsap, { TimelineMax } from "gsap";

import useInterval from "@use-it/interval";

import useStore from "../../../../store";
import Graph from "../../../shared/graph";
import Tag from "../../../shared/tag";

import s from "./evolution.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const speed = 1000; //stop motion speed

const Evolution = ({
  visuals: {
    bgColor,
    bgImage,
    bgOffset = "0%",
    bottomOffset = "0%",
    startPeriod,
    endPeriod,
    measure,
  },
  data,
  isActive,
  isPrev,
  tag = "",
  onSlideChange,
}) => {
  //data
  const productIDs = useRef(data.map((item) => item.product.id)).current;
  const scaledData = useRef([]);
  //calcs
  const idBuffer = useRef(0);
  const xBuffer = useRef(0);

  // gsap timelines
  const timelinePeriods = useRef(null);
  const timelineBg = useRef(null);

  //DOM
  const productRef = useRef(null);
  const wrapperRef = useRef(null);
  const graphMask = useRef(null);
  const bgMask = useRef(null);
  const imgRef = useRef(null);
  const offsetCoverRef = useRef(null);
  const startPeriodRef = useRef(null);
  const endPeriodRef = useRef(null);
  const startDataRef = useRef(null);
  const endDataRef = useRef(null);
  const bgRef = useRef(null);

  //used for domain bounds
  const maxX = useRef(data.length);
  const maxY = useRef(Math.max(...data.map((i) => i.y)));

  //loop
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);
  const [delay, setDelay] = useState(speed);
  const i = useRef(0);

  //get scaled data
  useEffect(() => {
    for (let i = 0; i < productIDs.length; i++) {
      //if product ids match -> best selling product for two or more consecutive months
      if (productIDs[i] !== productIDs[i + 1]) {
        scaledData.current.push({
          id: productIDs[i],
          x: (idBuffer.current / (data.length - 1)) * 100 + xBuffer.current,
          y: (data[i].y / maxY.current) * 100,
          clip: (data[i].x / data.length) * 100,
        });
        xBuffer.current += (idBuffer.current / (data.length - 1)) * 100;
        idBuffer.current = 0;
      }
      idBuffer.current++;
    }
  }, [data, endPeriod, productIDs, startPeriod]);

  //reset step counter and current data
  const resetPlot = useCallback(() => {
    gsap.set([graphMask.current, bgMask.current, offsetCoverRef.current], {
      WebkitClipPath: `inset(0 100% 0 0)`,
      clipPath: `inset(0 100% 0 0)`,
    });
    gsap.set(
      [
        startPeriodRef.current,
        endPeriodRef.current,
        startDataRef.current,
        endDataRef.current,
        imgRef.current,
      ],
      {
        autoAlpha: 0,
      }
    );
    imgRef.current.setAttribute("src", "");
    i.current = 0;
  }, []);

  // reset plot if slide is no longer in view
  useEffect(() => {
    isPrev && resetPlot();
  }, [isPrev, resetPlot]);

  // move product
  const moveProduct = useCallback(
    (delta) => {
      const c = scaledData.current[delta];

      //swap image
      const tl = new TimelineMax({});
      const imgURL = data.find((item) => item.product.id === c.id).product
        .image;
      tl.fromTo(
        imgRef.current,
        {
          autoAlpha: 1,
        },
        {
          autoAlpha: 0,
          rotate: 40,
          duration: 0.2,
          onStart: () => {
            const i = new Image();
            i.src = imgURL;
          },
        }
      ).to(imgRef.current, {
        autoAlpha: 1,
        rotate: 0,
        ease: "back.out(1.1)",
        duration: 0.3,
        delay: 0.3,
        onStart: () => {
          imgRef.current.setAttribute("src", imgURL);
        },
      });

      //transform
      const adj =
        c.x > 40
          ? productRef.current.clientWidth
          : productRef.current.clientWidth / 2; //move product inside the graph
      gsap.to(productRef.current, {
        x: `${(c.x / 100) * wrapperRef.current.clientWidth - adj}px`,
        y: `${-c.y}%`,
        duration: 0.3,
        delay: 0.3,
        ease: `Quint.easeInOut`,
      });
    },
    [data]
  );

  // uncover animation
  const uncoverGraph = useCallback(
    (delta) => {
      const c = (1 - scaledData.current[delta].clip / 100) * 100;
      gsap.to([graphMask.current, bgMask.current, offsetCoverRef.current], {
        WebkitClipPath: `inset(0 ${c}% 0 0)`,
        clipPath: `inset(0 ${c}% 0 0)`,
        duration: 0.3,
        ease: "Expo.easeInOut",
        onStart: () => {
          moveProduct(delta);
        },
      });
    },
    [moveProduct]
  );

  const onStart = () => {
    gsap.fromTo(
      startDataRef.current,
      {
        autoAlpha: 0,
        y: "-10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        delay: 0.3,
        duration: 0.3,
        stagger: 0.3,
        ease: "power4.out",
      }
    );
  };

  const onBeforeEnd = () => {
    gsap.fromTo(
      endDataRef.current,
      {
        autoAlpha: 0,
        y: "-10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        delay: 0.3,
        duration: 0.3,
        stagger: 0.3,
        ease: "power4.out",
      }
    );
  };

  const onEnd = () => {
    timelineBg.current.play();

    setTimeout(() => {
      onSlideChange();
    }, slideShowSpeed);
  };

  //loop (stop-motion)
  useInterval(() => {
    if (i.current >= scaledData.current.length || !isActive) {
      onEnd();
      setDelay(null);
      return;
    }
    i.current === 0 && onStart();
    i.current === scaledData.current.length - 1 && onBeforeEnd();
    uncoverGraph(i.current);
    i.current < scaledData.current.length && i.current++;
  }, delay);

  //cancel or retrigger loop
  useEffect(() => {
    (i.current >= scaledData.current.length || !isActive) && setDelay(null);
    i.current < scaledData.current.length && isActive && setDelay(speed);
  }, [isActive, i, scaledData.current.length]);

  //setup timelines
  useEffect(() => {
    timelinePeriods.current = new TimelineMax({ paused: true });
    timelineBg.current = new TimelineMax({ paused: true });

    timelinePeriods.current.fromTo(
      [startPeriodRef.current, endPeriodRef.current],
      {
        autoAlpha: 0,
        y: "-10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        delay: 0.3,
        duration: 0.3,
        stagger: 0.3,
        ease: "power4.out",
      }
    );

    timelineBg.current.fromTo(
      bgRef.current,
      {
        scale: 1,
      },
      {
        scale: 1.1,
        duration: 20,
      }
    );
  }, [bgRef, startPeriodRef, endPeriodRef]);

  useEffect(() => {
    isActive && timelinePeriods.current.play();
  }, [isActive]);

  useEffect(() => {
    if (isPrev) {
      timelinePeriods.current.invalidate().progress(0).pause();
      timelineBg.current.invalidate().progress(0).pause();
    }
  }, [isPrev]);

  return (
    <div
      className={cx(s.wrapper, { plotDone: i.current >= data.length - 1 })}
      style={{ backgroundColor: bgColor }}
      ref={wrapperRef}
    >
      <div className={s.visuals} style={{ bottom: bottomOffset }}>
        {/* if has background image */}
        {bgImage && (
          <div
            className={s.bgMask}
            ref={bgMask}
            style={{
              WebkitClipPath: "inset(0 100% 0 0 )",
              clipPath: "inset(0 100% 0 0 )",
            }}
          >
            <div
              className={cx(s.bgImage, s.clipped)}
              style={{ backgroundImage: `url(${bgImage})` }}
              ref={bgRef}
            />
          </div>
        )}
        {/* data */}
        <div className={s.graphWrapper}>
          <div className={s.productImage} ref={productRef}>
            <img
              ref={imgRef}
              src="data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
              alt="product"
            />
          </div>
          <div
            className={s.graphMask}
            ref={graphMask}
            style={{
              WebkitClipPath: "inset(0 100% 0 0 )",
              clipPath: "inset(0 100% 0 0 )",
            }}
          >
            <Graph
              plotData={data}
              xDomain={[1, maxX.current]}
              yDomain={[0, maxY.current]}
              color="#fff"
            />
          </div>
        </div>
        {/* clip bg */}
        <div className={s.inverse} style={{ top: bgOffset }}>
          <Graph
            plotData={data}
            xDomain={[1, maxX.current]}
            yDomain={[maxY.current, 0]} //domain inverse
            color={bgColor || "#fff"}
          />
          <div
            className={cx(s.cover)}
            style={{ backgroundColor: bgColor || "#fff" }}
          ></div>
        </div>
        <div
          className={s.offsetCover}
          ref={offsetCoverRef}
          style={{
            WebkitClipPath: "inset(0 100% 0 0 )",
            clipPath: "inset(0 100% 0 0 )",
          }}
        ></div>
      </div>
      <div className={s.periodStart} ref={startPeriodRef}>
        <span>{startPeriod}</span>
      </div>
      <div className={s.periodEnd} ref={endPeriodRef}>
        <span>{endPeriod}</span>
      </div>
      <div className={s.dataStart} ref={startDataRef}>
        <span>{data[0].product.value}</span>
        <span>{measure}</span>
      </div>
      <div className={s.dataEnd} ref={endDataRef}>
        <span>{data[data.length - 1].product.value}</span>
        <span>{measure}</span>
      </div>
      {tag && <Tag tag={tag} isActive={isActive} isPrev={isPrev} delay={0.5} />}
    </div>
  );
};

Evolution.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    start: PropTypes.object,
    end: PropTypes.object,
    hero: PropTypes.object,
    bgOffset: PropTypes.string,
    bottomOffset: PropTypes.string,
  }),
  data: PropTypes.array,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  tag: PropTypes.string,
};

export default Evolution;
