import React, { useRef, useEffect } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import s from "./tag.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const ItemValue = ({ tag, isActive, isPrev, delay }) => {
  const itemRef = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true });
    timeline.current.fromTo(
      itemRef.current,
      {
        autoAlpha: 0,
        x: "100%",
        scaleX: 1.2,
        transformOrigin: "0 0",
      },
      {
        autoAlpha: 1,
        x: "0%",
        scaleX: 1,
        duration: 0.42,
        delay,
        ease: "Power3.easeOut",
        immediateRender: true,
      }
    );
  }, [delay]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [delay, isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [delay, isPrev]);

  return (
    <div className={cx(s.wrapper)} ref={itemRef}>
      <span className={s.tag}>{tag}</span>
    </div>
  );
};

ItemValue.propTypes = {
  tag: PropTypes.string,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  delay: PropTypes.number,
};

export default ItemValue;
