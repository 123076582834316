import React, { useRef, useEffect } from "react";
import gsap, { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../store";
import s from "./foreground-background.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const ItemValue = ({
  data: { bg, image, imageWidth, imageOffset },
  isActive,
  isPrev,
  delay,
  index,
  onSlideChange,
}) => {
  const itemRef = useRef(null);
  const fgRef = useRef(null);
  const bgRef = useRef(null);
  const timeline = useRef(null);
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  useEffect(() => {
    timeline.current = new TimelineMax({
      paused: true,
      onComplete: () => {
        gsap.to(bgRef.current, {
          scale: 1 + Math.min(Math.random(), 0.05),
          y: "5%",
          transformOrigin: `${(Math.random() - 0.5) * 200}% 100%`,
          duration: 25,
        });
        isActive &&
          onSlideChange &&
          setTimeout(() => {
            onSlideChange();
          }, slideShowSpeed);
      },
    });
    timeline.current
      .fromTo(
        itemRef.current,
        {
          autoAlpha: 0,
        },
        {
          autoAlpha: 1,
          delay: delay,
          duration: 0.4,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        bgRef.current,
        {
          scale: 1,
          y: "0%",
          x: index % 2 ? "30%" : "-30%",
        },
        {
          scale: 1,
          y: "0%",
          x: "0%",
          delay: delay,
          duration: 0.6,
          ease: "Expo.easeOut",
        },
        `-= .5 + ${delay}`
      )
      .fromTo(
        fgRef.current,
        {
          autoAlpha: 0,
          y: "20%",
          rotate: 40,
        },
        {
          autoAlpha: 1,
          y: "0%",
          rotate: 0,
          delay: delay,
          ease: "back.out(1.1)",
          duration: 0.3,
        },
        0.3
      );
  }, [delay, index, isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.progress(0).play();
  }, [delay, isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [delay, isPrev]);

  return (
    <div className={cx(s.item)} ref={itemRef}>
      <div
        className={cx(s.fg, "fg-item")}
        ref={fgRef}
        style={
          imageWidth
            ? { width: imageWidth, left: `calc(50% + ${imageOffset})` }
            : {}
        }
      >
        <img src={image} alt="product" />
      </div>
      <div className={cx(s.bg, "bg-item")} ref={bgRef}>
        <img src={bg} alt="background" />
      </div>
    </div>
  );
};

ItemValue.propTypes = {
  data: PropTypes.shape({
    bg: PropTypes.string,
    image: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  delay: PropTypes.number,
  index: PropTypes.number,
  onSlideChange: PropTypes.func,
};

export default ItemValue;
