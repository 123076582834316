import React, { useRef, useEffect } from "react";
import { TimelineMax, TweenMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../../store";
import Graph from "../../../shared/graph";

import s from "./social-graph.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const delay = 400; //graph delay

const SocialGraph = ({
  visuals: { bgColor, bgImage, items, startPeriod, endPeriod },
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const bgRef = useRef();
  const innerRef = useRef();
  const graphWrapperRef = useRef();
  const itemsRef = useRef([]);

  //gsap
  const timeline = useRef(null);
  const bgTween = useRef(null);

  //mock domain for social chart, slideshow speed
  const data = useStore((s) => s.socialChart);
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  //used for domain bounds
  const maxX = data.length;
  const maxY = Math.max(...data.map((i) => i.y));

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true, delay: delay / 1000 });
    timeline.current
      .fromTo(
        innerRef.current,
        {
          autoAlpha: 1,
          y: "70%",
          transformOrigin: "0 0%",
          rotate: 90,
        },
        {
          y: "0%",
          rotate: 0,
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        itemsRef.current,
        {
          autoAlpha: 0,
          y: "10%",
          scale: 1.2,
        },
        {
          autoAlpha: 1,
          scale: 1,
          y: "0%",
          stagger: 0.2,
          duration: 0.3,
          ease: "power4.out",
          onComplete: () => {
            bgTween.current = TweenMax.fromTo(
              bgRef.current,
              {
                scale: 1,
              },
              {
                scale: 1.2,
                duration: 20,
              }
            );

            onSlideChange &&
              setTimeout(() => {
                onSlideChange();
                bgTween.current.kill();
              }, slideShowSpeed);
          },
        }
      );
  }, [itemsRef, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  //Item Component
  const Item = ({
    label,
    start,
    startSuffix,
    end,
    endSuffix,
    difference,
    differenceType,
    index,
  }) => {
    return (
      <div className={cx(s.item)} ref={(e) => (itemsRef.current[index] = e)}>
        <span className={cx(s.label)}>
          <span className={cx(s.labelInner)}>{label}</span>
        </span>
        <span className={cx(s.values)}>
          <span className={cx(s.start)}>
            <span className={cx(s.val)}>{start}</span>
            {startSuffix && <span className={cx(s.suffix)}>{startSuffix}</span>}
          </span>
          <div
            className={cx(s.difference, {
              decrease: differenceType === "decrease",
            })}
          >
            <span className={s.triangle}></span>
            {difference}
          </div>
          <span className={cx(s.end)}>
            <span className={cx(s.val)}>{end}</span>
            {endSuffix && <span className={cx(s.suffix)}>{endSuffix}</span>}
          </span>
        </span>
      </div>
    );
  };

  // Main return - render
  return (
    <div className={cx(s.wrapper, {})} style={{ backgroundColor: bgColor }}>
      <div className={s.visuals}>
        {/* graph */}
        <div className={s.inner} ref={innerRef}>
          <div className={s.contentWrapper}>
            <div className={s.graphWrapper} ref={graphWrapperRef}>
              <Graph
                plotData={data}
                xDomain={[1, maxX]}
                yDomain={[0, maxY]}
                color="#fff"
              />
            </div>
            {/* data */}
            <div className={s.period}>
              <span className={s.periodStart}>
                <span>{startPeriod}</span>
              </span>
              <span className={s.periodEnd}>
                <span>{endPeriod}</span>
              </span>
            </div>
            <div className={s.items}>
              {items.map((item, index) => (
                <Item
                  key={index}
                  label={item.label}
                  start={item.startVal}
                  startSuffix={item.startValSuffix}
                  end={item.endVal}
                  endSuffix={item.endValSuffix}
                  difference={item.difference}
                  differenceType={item.differenceType}
                  index={index}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      {/* if has background image */}
      {bgImage && (
        <div ref={bgRef} className={s.bgWrapper}>
          <div
            className={cx(s.bgImage)}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      )}
    </div>
  );
};

SocialGraph.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    items: PropTypes.array,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
};

export default SocialGraph;
