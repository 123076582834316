import React, { useState, useEffect, useRef, useCallback } from "react";
import PropTypes from "prop-types";

import useInterval from "@use-it/interval";

import useStore from "../../../../store";
import Graph from "../../../shared/graph";
import ItemValue from "../../../shared/item-value";
import BackgroundForeground from "../../../shared/background-foreground";
import Tag from "../../../shared/tag";

import s from "./top-three.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const speed = 30; //stop motion speed
const delay = 800; //graph delay

const TopThree = ({
  visuals: { items, bgColor },
  data,
  isActive,
  isPrev,
  tag = "",
  onSlideChange,
}) => {
  const [interval, setLoopInterval] = useState(speed);
  const i = useRef(0);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  //mock domain for first, second and third places
  const chartData = useStore((s) => s.podiumChart);

  //used for domain bounds
  const maxX = chartData.length;
  const maxY = Math.max(...chartData.map((i) => i.y));

  //current data - used for stop motion animation
  const [currentData, updateData] = useState(
    chartData.map((i) => ({ x: i.x, y: 0 }))
  );

  //initial delay
  setTimeout(() => isActive && !shouldAnimate && setShouldAnimate(true), delay);

  //reset step counter and current data
  const resetPlot = useCallback(() => {
    i.current = 0;
    setShouldAnimate(false);
    updateData(chartData.map((i) => ({ x: i.x, y: 0 })));
  }, [chartData]);

  //reset plot if slide is no longer in view
  useEffect(() => {
    isPrev && resetPlot();
  }, [isPrev, resetPlot]);

  //loop (stop-motion)
  useInterval(() => {
    if (i.current >= chartData.length || !isActive || !shouldAnimate) return;
    const tempData = [...currentData];
    tempData[i.current] = chartData[i.current];
    updateData(tempData);
    i.current++;
  }, interval);

  //cancel or retrigger loop
  useEffect(() => {
    (i.current >= chartData.length || !isActive) && setLoopInterval(null);
    i.current < chartData.length && isActive && setLoopInterval(speed);
  }, [currentData, chartData.length, isActive]);

  return (
    <div className={cx(s.wrapper)} style={{ backgroundColor: bgColor }}>
      <div className={s.visuals}>
        {items.map((item, index) => (
          <div className={s.visual} key={index}>
            <BackgroundForeground
              data={item}
              isActive={isActive}
              isPrev={isPrev}
              delay={(items.length - index - 1) * 0.4 + delay / 1000}
              index={items.length - index - 1}
              onSlideChange={index === items.length - 1 ? onSlideChange : null} // passing onSlideChange to last el.
            />
          </div>
        ))}
      </div>
      <Graph
        xDomain={[1, maxX]}
        yDomain={[0, maxY]}
        color="#fff"
        plotData={currentData}
        isActive={isActive}
        isPrev={isPrev}
        delay={2 + delay / 1000}
      />
      <div className={s.data}>
        {data.map((item, index) => (
          <div className={s.item} key={index}>
            <ItemValue
              data={item}
              isActive={isActive}
              isPrev={isPrev}
              delay={(items.length - index - 1) * 0.4 + delay / 1000}
            />
          </div>
        ))}
      </div>
      {tag && <Tag tag={tag} isActive={isActive} isPrev={isPrev} delay={0.5} />}
    </div>
  );
};

TopThree.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    items: PropTypes.arrayOf(Object),
  }),
  data: PropTypes.array,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  tag: PropTypes.string,
};

export default TopThree;
