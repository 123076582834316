import React, { useRef, useEffect } from "react";
import gsap, { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../../store";

import s from "./social-top-posts.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const delay = 400; //graph delay

const SocialTopPosts = ({
  visuals: { bgImage, bgColor, items },
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const innerRef = useRef();
  const rankShapesRef = useRef([]);
  const itemsRef = useRef([]);
  const timeline = useRef(null);

  //mock domain for social chart, slideshow speed
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true, delay: delay / 1000 });
    timeline.current
      .fromTo(
        innerRef.current,
        {
          autoAlpha: 1,
          y: "70%",
          transformOrigin: "0 0%",
          rotate: 90,
        },
        {
          y: "0%",
          rotate: 0,
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        itemsRef.current,
        {
          autoAlpha: 0,
          y: "10%",
          scale: 1.2,
        },
        {
          autoAlpha: 1,
          scale: 1,
          y: "0%",
          stagger: 0.2,
          duration: 0.3,
          ease: "power4.out",
          onComplete: () => {
            gsap.to(itemsRef.current, {
              scale: 1 + Math.min(Math.random(), 0.05),
              y: "5%",
              transformOrigin: `${(Math.random() - 0.5) * 200}% 100%`,
              duration: 25,
            });
            onSlideChange &&
              setTimeout(() => {
                onSlideChange();
              }, slideShowSpeed);
          },
        }
      );
    timeline.current.fromTo(
      rankShapesRef.current[1],
      {
        autoAlpha: 0,
        y: "70%",
        transformOrigin: "0 0%",
      },
      {
        autoAlpha: 1,
        y: "0%",
        duration: 0.5,
        ease: "Power3.easeInOut",
      }
    );
    timeline.current.fromTo(
      rankShapesRef.current[0],
      {
        autoAlpha: 0,
        y: "70%",
        transformOrigin: "0 0%",
      },
      {
        autoAlpha: 1,
        y: "0%",
        duration: 0.5,
        ease: "Power3.easeInOut",
      },
      "-=0.3"
    );
    timeline.current.fromTo(
      rankShapesRef.current[2],
      {
        autoAlpha: 0,
        y: "70%",
        transformOrigin: "0 0%",
      },
      {
        autoAlpha: 1,
        y: "0%",
        duration: 0.5,
        ease: "Power3.easeInOut",
      },
      "-=0.4"
    );
  }, [itemsRef, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  const Item = ({ bg, index }) => {
    return (
      <div className={cx(s.item)} ref={(e) => (itemsRef.current[index] = e)}>
        <div
          className={cx(s.bgImage)}
          style={{ backgroundImage: `url('${bg}')` }}
        />
      </div>
    );
  };

  return (
    <div className={cx(s.wrapper, {})} style={{ backgroundColor: bgColor }}>
      <div className={s.visuals}>
        <div className={s.inner} ref={innerRef}>
          <div className={s.items}>
            {items.map((item, index) => (
              <Item key={index} bg={item.bg} rank={item.rank} index={index} />
            ))}
            {items.map((item, index) => (
              <div
                key={`rank${item.rank}`}
                className={cx(s.rankShape, s.rank, `rank${item.rank}`)}
                ref={(e) => (rankShapesRef.current[index] = e)}
              >
                <div className={s.rankCaption}>
                  <div className={s.rankTitle}>N&ordm;{item.rank}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

SocialTopPosts.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    items: PropTypes.array,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
};

export default SocialTopPosts;
