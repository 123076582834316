import React, { useRef, useEffect } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import s from "./item-value.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const truncate = (input, max = 3) => {
  let result = input.split(" ").splice(0, max).join(" ");

  if (input.split(" ").length > max) {
    result += "...";
  }

  return result;
};

const ItemValue = ({
  data: { title, value, measure },
  isActive,
  isPrev,
  delay,
}) => {
  const itemRef = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true });
    timeline.current.fromTo(
      itemRef.current,
      {
        autoAlpha: 0,
        y: "-10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        delay: 0.4 + delay,
        duration: 0.3,
        ease: "power4.out",
      }
    );
  }, [delay]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [delay, isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [delay, isPrev]);

  return (
    <div className={cx(s.item)} ref={itemRef}>
      <span className={cx(s.title)}>{truncate(title)}</span>
      <span className={cx(s.value)}>{value}</span>
      <span className={cx(s.measure)}>{measure}</span>
    </div>
  );
};

ItemValue.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    measure: PropTypes.string,
  }),
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  delay: PropTypes.number,
};

export default ItemValue;
