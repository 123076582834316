import React, { useEffect, useState, useRef } from "react";
import { XYPlot, XAxis, YAxis, AreaSeries } from "react-vis";
import gsap, { TimelineMax } from "gsap";

import { useWindowSize } from "@react-hook/window-size";
import useStore from "../../../store";

// import s from "./graph.module.scss";
// import classNames from "classnames/bind";
// const cx = classNames.bind(s);

const Graph = ({
  plotData,
  xDomain,
  yDomain,
  color,
  className = "",
  yScale = 1,
  isActive,
  isPrev,
  delay,
  onSizeSet
}) => {
  //layout utils
  const c = useRef((100 - parseInt(useStore((s) => s.indent))) / 100); //width coefficient
  const offsetFix = useRef(`translate(-40px, 40px)`);
  const yAdjust = useRef(`scaleY(${yScale})`);
  const wrapperRef = useRef(null);
  const timeline = useRef(null);

  const [viewportWidth, viewportHeight] = useWindowSize();
  const [size, setSize] = useState({
    w: viewportWidth * c.current + 50,
    h: viewportHeight * 0.5,
  });

  //update width and height values on resize
  useEffect(() => {
    setSize({
      w: viewportWidth * c.current + 50, // 50 - default offset fix
      h: viewportHeight * 0.5,
    });
    onSizeSet && onSizeSet(viewportHeight * 0.5)
  }, [onSizeSet, viewportHeight, viewportWidth]);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true });
    timeline.current.fromTo(
      wrapperRef.current,
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        transformOrigin: `${(Math.random() - 0.5) * 200} 100%`,
      },
      {
        scale: 1.1,
        y: "3%",
        x: `${Math.random() - 0.5 * 5}%`,
        duration: 20,
        delay,
      }
    );
  }, [delay]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [delay, isActive]);

  useEffect(() => {
    if(isPrev)  {
      timeline.current.invalidate().progress(0).pause();
      gsap.set(wrapperRef.current, {clearProps: 'all'})
    }
  }, [delay, isPrev]);

  return (
    <div ref={wrapperRef}>
      <div
        style={{
          transform: yAdjust.current,
          transformOrigin: "0 100%",
          width: "100%",
        }}
      >
        <XYPlot
          width={size.w}
          height={size.h}
          xDomain={xDomain}
          yDomain={yDomain}
          style={{ transform: offsetFix.current }}
          className={className}
        >
          <AreaSeries data={plotData} color={color} />
          <XAxis />
          <YAxis />
        </XYPlot>
      </div>
    </div>
  );
};

export default Graph;
