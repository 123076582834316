import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import PropTypes from "prop-types";

import s from "./info.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const delay = 0.0;
const CHAR_CHANGE_SIZE_COUNT = 18;
const WORD_CHANGE_SIZE_COUNT = 8;

const Info = ({
  content: { title, subtitle, category, description },
  bg: { type, value },
  isActive,
}) => {
  const categoryWrapper = useRef(null);
  const titleWrapper = useRef(null);
  const subtitleWrapper = useRef(null);
  const descriptionWrapper = useRef(null);

  useEffect(() => {
    gsap.fromTo(
      [
        categoryWrapper.current,
        titleWrapper.current,
        subtitleWrapper.current,
        descriptionWrapper.current,
      ],
      {
        y: "40%",
        scaleY: 1.6,
        skewY: 10,
        transformOrigin: "0 0",
        autoAlpha: 0,
      },
      {
        y: "0%",
        scaleY: 1,
        skewY: 0,
        autoAlpha: 1,
        duration: 0.22,
        stagger: 0.2,
        delay,
        ease: "Quad.easeInOut",
        immediateRender: true,
      }
    );
  }, [
    isActive,
    categoryWrapper,
    titleWrapper,
    subtitleWrapper,
    descriptionWrapper,
  ]);

  const hasLongText = (title) => {
    let words = null;
    if (title.length >= CHAR_CHANGE_SIZE_COUNT) {
      return true;
    } else {
      words = title.split(" ");
      for (let i = 0; i < words.length; i++) {
        if (words[i].length >= WORD_CHANGE_SIZE_COUNT) {
          return true;
        }
      }
    }
    return false;
  };

  return (
    <div
      className={cx(s.wrapper, { isActive }, { whiteText: value !== "#fff" })}
      style={
        type === "solid"
          ? { background: value }
          : { backgroundImage: `url('${value}')` }
      }
    >
      <div className={s.categoryWrapper} ref={categoryWrapper}>
        <h4 className={s.category}>{category}</h4>
      </div>
      <div className={s.titleWrapper} ref={titleWrapper}>
        <h1 className={cx(hasLongText(title) ? s.titleSmall : s.title)}>
          {title}
        </h1>
      </div>
      <div className={s.subtitleWrapper} ref={subtitleWrapper}>
        <h2
          className={cx(hasLongText(title) ? s.subtitleSmall : s.subtitle)}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </div>
      {description && (
        <div className={s.descriptionWrapper} ref={descriptionWrapper}>
          <h5
            className={cx(
              hasLongText(description) ? s.descriptionSmall : s.description
            )}
          >
            {description}
          </h5>
        </div>
      )}
    </div>
  );
};

Info.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    category: PropTypes.string.isRequired,
    description: PropTypes.string,
  }),
  bg: PropTypes.shape({
    type: PropTypes.string.isRequired,
    value: PropTypes.string,
  }),
  isActive: PropTypes.bool,
};

export default Info;
