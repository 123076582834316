import create from "zustand";

const DEBUG_ENABLED = /debug/.test(window.location.href);

const [useStore] = create((set) => ({
  slideShowSpeed: DEBUG_ENABLED ? 1000 : 6000,
  indent: null,
  setIndent: (indent) => set((state) => ({ indent })),
  activeIndex: 0,
  setActiveIndex: (activeIndex) => set((state) => ({ activeIndex })),
  prevIndex: 0,
  setPrevIndex: (prevIndex) => set((state) => ({ prevIndex })),
  total: null,
  setTotal: (total) => set((state) => ({ total })),
  podiumChart: [
    { x: 1, y: 9 },
    { x: 2, y: 11.7 },
    { x: 3, y: 9 },
    { x: 4, y: 14.9 },
    { x: 5, y: 8.5 },
    { x: 5.8, y: 11 },
    { x: 7, y: 8.3 },
  ],
  socialChart: [
    { x: 1, y: 0 },
    { x: 3.5, y: 1 },
    { x: 4, y: 0.5 },
    { x: 5, y: 2 },
    { x: 5.8, y: 1.6 },
    { x: 7, y: 2.2 },
    { x: 8.5, y: 1.6 },
    { x: 9, y: 1.2 },
    { x: 10, y: 3 },
    { x: 12, y: 8 },
  ],
}));

export default useStore;
