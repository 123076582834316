import React, { useRef, useEffect } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import Graph from "../../../shared/graph";
import GraphInfo from "../../../shared/graph-info";
import GraphHero from "../../../shared/graph-hero";

import s from "./progress-plot.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const ProgressPlot = ({
  visuals: {
    bgColor,
    bgImage,
    start,
    end,
    hero,
    yScale = 1,
    yOffset = "0%",
    isFlipped = false,
  },
  data,
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const bgRef = useRef();
  const innerRef = useRef();
  const timeline = useRef(null);

  //used for domain bounds
  const maxX = useRef(data.length);
  const maxY = useRef(Math.max(...data.map((i) => i.y)));

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true, delay: 0 });
    timeline.current
      .fromTo(
        bgRef.current,
        {
          x: isFlipped ? "-100%" : "100%",
          immediateRender: true,
        },
        { duration: 0.4, x: "0%", autoAlpha: 1, ease: "Quint.easeInOut" }
      )
      .fromTo(
        innerRef.current,
        {
          autoAlpha: 0,
          y: !isFlipped ? "120%" : "-120%",
          transformOrigin: !isFlipped ? "0 0%" : "0% 100%",
          rotate: !isFlipped ? 90 : -90,
        },
        {
          autoAlpha: 1,
          y: "0%",
          rotate: 0,
          duration: 0.5,
          ease: "Power3.easeInOut",
        }
      )
      .fromTo(
        bgRef.current,
        {
          scale: 1,
          transformOrigin: !isFlipped ? "0 50%" : "100% 50%",
        },
        {
          scale: 1.1,
          duration: 20,
        },
        -0.2
      );
  }, [isFlipped]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  return (
    <div
      className={cx(s.wrapper, { isFlipped })}
      style={{ backgroundColor: bgColor }}
    >
      <div className={s.visuals}>
        {/*graph: normal (with optional clipped image) or  flipped*/}
        <div className={s.inner} ref={innerRef}>
          {!isFlipped && (
            <Graph
              plotData={data}
              xDomain={[1, maxX.current]}
              yDomain={[0, maxY.current]}
              yScale={yScale}
              color="#fff"
            />
          )}
          {isFlipped && (
            <div className={s.inverse} style={{ top: yOffset }}>
              <Graph
                plotData={data}
                xDomain={[1, maxX.current]}
                yDomain={[maxY.current, 0]} //domain inverse
                yScale={yScale} //check yScale here
                color={bgColor || "#fff"}
              />
              <div
                className={cx(s.cover)}
                style={{ backgroundColor: bgColor || "#fff" }}
              ></div>
            </div>
          )}
        </div>
        {/* graph info */}
        {start && (
          <GraphInfo
            value={start.value}
            measure={start.measure}
            time={start.time}
            suffix={start.suffix}
            type="start"
            layout={isFlipped ? "flipped" : "normal"}
            isPrev={isPrev}
            isActive={isActive}
            delay={0.8}
          />
        )}
        {end && (
          <GraphInfo
            value={end.value}
            measure={end.measure}
            time={end.time}
            suffix={end.suffix}
            type="end"
            layout={isFlipped ? "flipped" : "normal"}
            isActive={isActive}
            isPrev={isPrev}
            delay={1}
          />
        )}
        {hero && (
          <GraphHero
            value={hero.value}
            prefix={hero.prefix}
            suffix={hero.suffix}
            layout={isFlipped ? "flipped" : "normal"}
            isActive={isActive}
            isPrev={isPrev}
            delay={1.2}
            onSlideChange={onSlideChange}
          />
        )}
      </div>
      {/* if has background image */}
      {bgImage && (
        <div ref={bgRef} className={s.bgWrapper}>
          <div
            className={cx(s.bgImage)}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      )}
    </div>
  );
};

ProgressPlot.propTypes = {
  visuals: PropTypes.shape({
    bgColor: PropTypes.string,
    bgImage: PropTypes.string,
    start: PropTypes.object,
    end: PropTypes.object,
    hero: PropTypes.object,
    yOffset: PropTypes.string,
    yScale: PropTypes.number,
    isFlipped: PropTypes.bool,
  }),
  data: PropTypes.array,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
};

export default ProgressPlot;
