import React, { useRef, useEffect } from "react";
import { gsap } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../../store";

import s from "./message.module.scss";

const Message = ({ data, visuals, isActive, onSlideChange }) => {
  const video = useRef();
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  const isImage = data.media.kind === "image";
  const splitUrl = data.media.url.split(".");
  const mediaType = splitUrl[splitUrl.length - 1];

  const bgMedia = useRef(null);
  const image = useRef(null);

  useEffect(() => {
    if (!isActive) return;
    let timerId = null;
    gsap.fromTo(
      bgMedia.current,
      {
        autoAlpha: 0,
      },
      {
        autoAlpha: 1,
        duration: 0.8,
        ease: "Expo.easeOut",
        onComplete: () => {
          if (isActive && video.current) {
            video.current.play();
          }
          if (isActive && isImage) {
            gsap.to(image.current, {
              scale: 1 + Math.min(Math.random(), 0.05),
              y: "5%",
              transformOrigin: `${(Math.random() - 0.5) * 200}% 100%`,
              duration: 25,
            });
            timerId = setTimeout(() => {
              onSlideChange && onSlideChange();
            }, slideShowSpeed);
          }
        },
      }
    );
    return () => {
      clearTimeout(timerId);
    };
  }, [isActive, isImage, onSlideChange, slideShowSpeed]);

  const handleVideoEnd = () => {
    isActive && onSlideChange && onSlideChange();
  };

  return (
    <div className={s.wrapper} ref={bgMedia}>
      {isImage && (
        <img ref={image} src={visuals?.bgMedia} alt={data.media.name} />
      )}
      {!isImage && (
        <video ref={video} muted onEnded={handleVideoEnd}>
          <source src={visuals?.bgMedia} type={`video/${mediaType}`} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

Message.propTypes = {
  data: PropTypes.object.isRequired,
  visuals: PropTypes.shape({
    bgMedia: PropTypes.string.isRequired,
  }),
};

export default Message;
