import React, { useRef, useEffect } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import s from "./graph-info.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const GraphInfo = ({
  measure,
  time,
  value,
  suffix,
  type,
  layout,
  isActive,
  isPrev,
  delay,
}) => {
  const itemRef = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true });
    timeline.current.fromTo(
      itemRef.current,
      {
        autoAlpha: 0,
        y: layout === "normal" ? "-10%" : "10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        stagger: 0.2,
        delay: 0.2 + delay,
        duration: 0.3,
        ease: "power4.out",
      }
    );
  }, [delay, layout]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [delay, isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [delay, isPrev]);

  return (
    <div className={cx(s.wrapper, type, layout)} ref={itemRef}>
      <div className={cx(s.top)}>
        {measure && <div className={cx(s.measure)}>{measure}</div>}
        <div className={cx(s.value)}>{value}</div>
        {suffix && <div className={cx(s.suffix)}>{suffix}</div>}
      </div>
      <div className={cx(s.bottom)}>
        <div className={cx(s.time)}>{time}</div>
      </div>
    </div>
  );
};

GraphInfo.propTypes = {
  measure: PropTypes.string,
  value: PropTypes.string,
  suffix: PropTypes.string,
  time: PropTypes.string,
  layout: PropTypes.string,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  delay: PropTypes.number,
};

export default GraphInfo;
