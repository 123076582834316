import React, { useEffect, useRef } from "react";
import { TimelineMax } from "gsap";
import PropTypes from "prop-types";

import useStore from "../../../store";
import s from "./graph-hero.module.scss";
import classNames from "classnames/bind";
const cx = classNames.bind(s);

const GraphHero = ({
  prefix,
  value,
  suffix,
  layout,
  delay,
  isActive,
  isPrev,
  onSlideChange,
}) => {
  const itemRef = useRef(null);
  const timeline = useRef(null);
  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  useEffect(() => {
    timeline.current = new TimelineMax({ paused: true });
    timeline.current.fromTo(
      itemRef.current,
      {
        autoAlpha: 0,
        x: "30%",
        skewX: layout === 'normal' ? -10 : 10,
        scaleX: 2,
        transformOrigin: "0 0",
      },
      {
        autoAlpha: 1,
        x: "0%",
        skewX: 0,
        scaleX: 1,
        duration: 0.3,
        delay,
        ease: "Power3.easeInOut",
        onComplete: () => {
          onSlideChange &&
            setTimeout(() => {
              onSlideChange();
            }, slideShowSpeed);
        },
      }
    );
  }, [delay, layout, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.play();
  }, [delay, isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [delay, isPrev]);

  return (
    <div className={cx(s.wrapper, layout)} ref={itemRef}>
      {prefix && <div className={cx(s.prefix)}>{prefix}</div>}
      <div className={cx(s.value)}>{value}</div>
      {suffix && <div className={cx(s.suffix)}>{suffix}</div>}
    </div>
  );
};

GraphHero.propTypes = {
  prefix: PropTypes.string,
  value: PropTypes.string,
  suffix: PropTypes.string,
  layout: PropTypes.string,
  isActive: PropTypes.bool,
  isPrev: PropTypes.bool,
  delay: PropTypes.number,
  onSlideChange: PropTypes.func,
};

export default GraphHero;
