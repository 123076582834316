import React, { useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { TimelineMax } from "gsap";

import Graph from "../../../shared/graph";
import BackgroundForeground from "../../../shared/background-foreground";

import useStore from "../../../../store";

import s from "./product-launch.module.scss";

const ProductLaunch = ({ data, visuals, isActive, isPrev, onSlideChange }) => {
  const description = data?.description?.[0]?.text;

  const slideShowSpeed = useStore((s) => s.slideShowSpeed);

  const descriptionRef = useRef(null);
  const timeline = useRef(null);

  useEffect(() => {
    timeline.current = new TimelineMax({
      paused: true,
      onComplete: () => {
        isActive &&
          onSlideChange &&
          setTimeout(() => {
            onSlideChange();
          }, slideShowSpeed);
      },
    });
    timeline.current.fromTo(
      descriptionRef.current,
      {
        autoAlpha: 0,
        y: "-10%",
        scale: 1.2,
      },
      {
        autoAlpha: 1,
        scale: 1,
        y: "0%",
        delay: 0.8,
        duration: 0.3,
        ease: "power4.out",
      }
    );
  }, [isActive, onSlideChange, slideShowSpeed]);

  useEffect(() => {
    isActive && timeline.current.progress(0).play();
  }, [isActive]);

  useEffect(() => {
    isPrev && timeline.current.invalidate().progress(0).pause();
  }, [isPrev]);

  return (
    <div className={s.wrapper}>
      <div className={s.visuals}>
        <div className={s.visual}>
          <BackgroundForeground
            data={{ image: data?.media?.url, bg: visuals?.bgMedia }}
            isActive={isActive}
            delay={800 / 1000 + 0.1}
            index={0}
          />
        </div>
      </div>
      <Graph
        plotData={[
          { x: 20, y: 0 },
          { x: 80, y: 200 },
          { x: 120, y: 60 },
        ]}
        xDomain={[1, 100]}
        yDomain={[0, 200]}
        color="#fff"
      />
      <div className={s.description} ref={descriptionRef}>
        {description}
      </div>
    </div>
  );
};

ProductLaunch.propTypes = {
  data: PropTypes.object.isRequired,
  visuals: PropTypes.shape({
    bgMedia: PropTypes.string.isRequired,
  }),
};

export default ProductLaunch;
